import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

export default function CertificationOption(props) {
  const { className, link, icon, title, text, usePdf } = props

  const renderPdfIcon = () => {
    if (!usePdf) {
      return <Fragment></Fragment>
    }
    return (
      <div className="CertificationOption-pdf">
        <i className="far fa-file-pdf"></i>
      </div>
    )
  }

  return (
    <div
      className={`CertificationOption ${className} ${usePdf ? "usePdf" : ""}`}
    >
      <Link to={link} className="CertificationOption-link">
        <i className={icon}></i>
        <div className="CertificationOption-info">
          <span className="CertificationOption-title">{title}</span>
          <span className="CertificationOption-text">{text}</span>
        </div>
        {renderPdfIcon()}
      </Link>
    </div>
  )
}

CertificationOption.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  usePdf: PropTypes.bool,
}

CertificationOption.defaultProps = {
  className: "",
  link: "/",
  icon: "",
  title: "",
  text: "",
  usePdf: false,
}
