import React, { Fragment, useEffect, useState } from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Breadcrumb from "../components/Breadcrumb"
import TitleBlock from "../components/TitleBlock"
import CertificationOption from "../components/CertificationOption"
import Announcement from "../components/Announcement"
import parse from "react-html-parser"
import { useAxiosClient } from "../service/useAxiosClient"
import TitlePage from "../components/TitlePage"

export default function DatesExams({ location }) {
  const [test, setTest] = useState({})

  const [{ data: dataTest }] = useAxiosClient({
    url: `/exams/dates/web/next-calls/`,
    method: "GET",
  })

  useEffect(() => {
    if (dataTest) {
      setTest(dataTest)
    }
  }, [dataTest])

  const path = [
    { label: "Inicio", to: "/" },
    {
      label: "Certificaciones - Próximos exámenes",
    },
  ]

  const setLinkById = id => {
    switch (id) {
      case "EFP":
        return "/examenes-certificacion-efp"
      case "Nivel II EFA":
        return "/examenes-certificacion-efa-nivel-II"
      case "EIA":
        return "/examenes-certificacion-eia"
      case "EFA":
        return "/examenes-certificacion-efa"
      case "EIP":
        return "/examenes-certificacion-eip"
      case "ESG":
        return "/efpa-esg"
      default:
        return "/"
    }
  }

  const getIconByName = name => {
    switch (name) {
      case "EFP":
        return "fas fa-sitemap"
      case "EFA":
        return "fas fa-user-tie"
      case "EIA":
        return "far fa-id-card"
      case "EIP":
        return "far fa-file-alt"
      case "ESG":
        return "fab fa-envira"
      case "LCCI":
        return "far fa-id-card"
      case "Nivel II EFA":
        return "fas fa-user-tie"

      default:
        return "/"
    }
  }

  const renderContent = () => {
    return (
      test &&
      Object.entries(test).map(([key, value]) => (
        <Fragment>
          <CertificationOption
            icon={getIconByName(key)}
            title={key}
            text={value?.subtitle}
            link={setLinkById(key)}
            className="mb-3"
          />
          <div className="row efpa-row">{renderOptions(value.calls)}</div>
        </Fragment>
      ))
    )
  }

  const renderOptions = options => {
    return (
      options &&
      options.map(item => {
        return (
          <div className="col-12 col-md-6 Certification-padding-r">
            <Announcement
              title={item?.call_text}
              link={item?.activity_link}
              date={item?.exams_date_text}
              year={parse(item?.comments)}
              linkBtn={item?.inscription_link}
            />
          </div>
        )
      })
    )
  }

  return (
    <Layout>
      <TitlePage
        title="Próximas convocatorias exámenes"
        url={location.pathname}
      />
      <Hero image="/imgs/certificaciones-hero.jpg" text="Certificaciones" />
      <div className="container950">
        <div className="DatesExams">
          <Breadcrumb path={path} />
          <TitleBlock title="Próximos exámenes" icon="far fa-calendar-alt" />
          {renderContent()}
        </div>
      </div>
    </Layout>
  )
}
