import React, { Fragment } from "react"
import { Link } from "gatsby"
import Proptypes from "prop-types"

export default function Announcement(props) {
  const { className, title, link, date, year, linkBtn } = props
  const renderBtn = () => {
    if (!linkBtn) {
      return <Fragment></Fragment>
    }
    return (
      <Link className="EfpaEurope-link" to={linkBtn}>
        <i className="fas fa-check-square"></i>
        Inscribirme ahora
      </Link>
    )
  }
  return (
    <div className={`Announcement ${className}`}>
      <Link className="Announcement-link" to={link}>
        {title}
      </Link>
      <span className="Announcement-date ">{date}</span>
      <div className="Announcement-content">
        <span className="Announcement-year">{year}</span>
      </div>
      {renderBtn()}
    </div>
  )
}

Announcement.propTypes = {
  className: Proptypes.string,
  title: Proptypes.string,
  link: Proptypes.string,
  date: Proptypes.string,
  year: Proptypes.string,
  linkBtn: Proptypes.string,
}

Announcement.defaultProps = {
  className: "",
  title: "",
  link: "",
  date: "",
  year: "",
  linkBtn: "",
}
